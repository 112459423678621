"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainNavModule = void 0;
function mainNavModule() {
    var mainHeader = document.querySelector('#section-main-header');
    var mainNavControllers = document.querySelectorAll('#mainNav-overlay, #button-mainNav-trigger');
    if (!mainHeader) {
        console.warn('Main header element not found');
        return;
    }
    var toggleMenuOnClick = function (item) {
        item.addEventListener('click', function () {
            mainHeader.classList.toggle('menuOpen');
        });
    };
    if (mainNavControllers.length > 0) {
        mainNavControllers.forEach(function (i) {
            toggleMenuOnClick(i);
        });
    }
}
exports.mainNavModule = mainNavModule;
