"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inlineScrollModule = void 0;
function inlineScrollModule() {
    var inlineScrollers = document.querySelectorAll('.inlineScroll-scroller');
    if (inlineScrollers.length > 0) {
        inlineScrollers.forEach(function (i) {
            var inlineStartDetector = i.querySelector('.inlineScroll-inlineStartDetector');
            var inlineEndDetector = i.querySelector('.inlineScroll-inlineEndDetector');
            if (!inlineStartDetector || !inlineEndDetector) {
                console.warn('At least one inlineScroll detector not found.');
                return;
            }
            var extremitiesCallback = function (entries) {
                entries.forEach(function (entry) {
                    var intersectionRatio = entry.intersectionRatio, target = entry.target; // Destructuring: affectation par décomposition.
                    if (target === inlineStartDetector) {
                        i.classList.toggle('displayNoneBefore', intersectionRatio > 0);
                    }
                    else if (target === inlineEndDetector) {
                        i.classList.toggle('displayNoneAfter', intersectionRatio > 0);
                    }
                });
            };
            var extremitiesOptions = {
                root: i
            };
            var extremitiesObserver = new IntersectionObserver(extremitiesCallback, extremitiesOptions);
            extremitiesObserver.observe(inlineStartDetector);
            extremitiesObserver.observe(inlineEndDetector);
        });
    }
}
exports.inlineScrollModule = inlineScrollModule;
