"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropdownModule = void 0;
function dropdownModule() {
    var DROPDOWN_CONTROLLER_CLASS = 'dropdown-controller';
    var DROPDOWN_OPEN_CLASS = 'dropdownOpen';
    var dropdownControllers = document.querySelectorAll(".".concat(DROPDOWN_CONTROLLER_CLASS));
    if (dropdownControllers.length > 0) {
        var tellDropdownIsOpen_1 = function (event) {
            var target = event.target;
            if (target) {
                var dropdownController = target.closest(".".concat(DROPDOWN_CONTROLLER_CLASS));
                if (dropdownController) {
                    var parent_1 = dropdownController.closest(":not(.".concat(DROPDOWN_CONTROLLER_CLASS, ")"));
                    if (parent_1) {
                        parent_1.classList.toggle(DROPDOWN_OPEN_CLASS);
                    }
                }
            }
        };
        dropdownControllers.forEach(function (i) {
            i.addEventListener('click', tellDropdownIsOpen_1);
        });
        // To close open dropdowns when we click outside.
        var closeOpenDropdowns_1 = function (event) {
            var target = event.target;
            if (target) {
                var openDropdowns = document.querySelectorAll(".".concat(DROPDOWN_OPEN_CLASS));
                openDropdowns.forEach(function (i) {
                    if (!i.contains(target)) {
                        i.classList.remove(DROPDOWN_OPEN_CLASS);
                    }
                });
            }
        };
        document.addEventListener('click', closeOpenDropdowns_1);
        // Cleanup event listeners when the module is no longer needed
        return function () {
            dropdownControllers.forEach(function (i) {
                i.removeEventListener('click', tellDropdownIsOpen_1);
            });
            document.removeEventListener('click', closeOpenDropdowns_1);
        };
    }
}
exports.dropdownModule = dropdownModule;
