"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainHeaderModule = void 0;
function mainHeaderModule() {
    var mainHeader = document.querySelector('#section-main-header');
    if (!mainHeader) {
        console.warn('Main header element not found.');
        return;
    }
    // We want to let the user scroll 40 pixels down before we change the header's appearance.
    var SCROLL_THRESHOLD = 40;
    var CLASS_NAMES = ['-secondaryColorBG', '-withShadow', '-pageScrolled'];
    var adaptHeaderAccordingToScrollY = function () {
        var _a, _b;
        if (window.scrollY >= SCROLL_THRESHOLD) {
            (_a = mainHeader.classList).add.apply(_a, CLASS_NAMES);
        }
        else {
            (_b = mainHeader.classList).remove.apply(_b, CLASS_NAMES);
        }
    };
    document.addEventListener('scroll', adaptHeaderAccordingToScrollY);
    document.addEventListener('DOMContentLoaded', adaptHeaderAccordingToScrollY);
    // Cleanup event listeners when the module is no longer needed
    return function () {
        document.removeEventListener('scroll', adaptHeaderAccordingToScrollY);
        document.removeEventListener('DOMContentLoaded', adaptHeaderAccordingToScrollY);
    };
}
exports.mainHeaderModule = mainHeaderModule;
